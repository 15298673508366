import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";
import {HappyProvider} from "@ant-design/happy-work-theme";
import {StyleProvider} from "@ant-design/cssinjs";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HappyProvider>
            <StyleProvider hashPriority="high">
                <ConfigProvider
                    theme={{
                        "token": {
                            "colorPrimary": "#5d76ba",
                            "colorInfo": "#5d76ba",
                            "colorSuccess": "#89b3a1",
                            "colorWarning": "#f5c194",
                            "colorError": "#f39894"
                        },
                    }}
                >
                    <App/>
                </ConfigProvider>
            </StyleProvider>
        </HappyProvider>
    </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
