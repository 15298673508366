import './App.css';
import {Col, ConfigProvider, FloatButton, notification, Progress, Row, Statistic, Tabs} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {SettingOutlined} from "@ant-design/icons";
import axios from "axios";

const {Countdown} = Statistic;

function endOfDay() {
    return dayjs().endOf('date').unix() < 1703289599 ? dayjs().endOf('date').unix() * 1000 : 1703289599000
}

function endOfMonth() {
    return dayjs().endOf('month').unix() < 1703289599 ? dayjs().endOf('month').unix() * 1000 : 1703289599000
}

function monthDays() {
    switch (dayjs().month()) {
        case 1:
            return 31
        case 2:
            return dayjs().isLeapYear() ? 29 : 28
        case 3:
            return 31
        case 4:
            return 30
        case 5:
            return 31
        case 6:
            return 30
        case 7:
            return 31
        case 8:
            return 31
        case 9:
            return 30
        case 10:
            return 31
        case 11:
            return 30
        case 12:
            return 31
        default:
            return 30
    }
}

const timePeriod = [
    {
        time: 800,
        state: '休息',
    },
    {
        time: 825,
        state: '学习',
    },
    {
        time: 830,
        state: '休息',
    },
    {
        time: 855,
        state: '学习',
    },
    {
        time: 900,
        state: '休息',
    },
    {
        time: 925,
        state: '学习',
    },
    {
        time: 935,
        state: '休息',
    },
    {
        time: 1000,
        state: '学习',
    },
    {
        time: 1005,
        state: '休息',
    },
    {
        time: 1030,
        state: '学习',
    },
    {
        time: 1040,
        state: '休息',
    },
    {
        time: 1105,
        state: '学习',
    },
    {
        time: 1110,
        state: '休息',
    },
    {
        time: 1200,
        state: '学习',
    },
    {
        time: 1400,
        state: '休息',
    },
    {
        time: 1425,
        state: '学习',
    },
    {
        time: 1430,
        state: '休息',
    },
    {
        time: 1455,
        state: '学习',
    },
    {
        time: 1500,
        state: '休息',
    },
    {
        time: 1525,
        state: '学习',
    },
    {
        time: 1535,
        state: '休息',
    },
    {
        time: 1600,
        state: '学习',
    },
    {
        time: 1605,
        state: '休息',
    },
    {
        time: 1630,
        state: '学习',
    },
    {
        time: 1640,
        state: '休息',
    },
    {
        time: 1705,
        state: '学习',
    },
    {
        time: 1710,
        state: '休息',
    },
    {
        time: 1800,
        state: '学习',
    },
    {
        time: 1900,
        state: '休息',
    },
    {
        time: 1925,
        state: '学习',
    },
    {
        time: 1930,
        state: '休息',
    },
    {
        time: 1955,
        state: '学习',
    },
    {
        time: 2000,
        state: '休息',
    },
    {
        time: 2025,
        state: '学习',
    },
    {
        time: 2035,
        state: '休息',
    },
    {
        time: 2100,
        state: '学习',
    },
    {
        time: 2105,
        state: '休息',
    },
    {
        time: 2130,
        state: '学习',
    },
    {
        time: 2140,
        state: '休息',
    },
    {
        time: 2205,
        state: '学习',
    },
    {
        time: 2210,
        state: '休息',
    },
    {
        time: 2300,
        state: '学习',
    }
]

function nextTimePeriod() {
    let now = dayjs().hour() * 100 + dayjs().minute()

    for (let i = 0; i < timePeriod.length; i++) {
        if (now < timePeriod[i].time) {
            return {
                state: timePeriod[i].state,
                end: dayjs().startOf('date').set('hour', timePeriod[i].time / 100).set('minute', timePeriod[i].time % 100).unix() * 1000,
            }
        }
    }

    return {
        state: '休息',
        end: dayjs().startOf('date').add(1, 'day').set('hour', 8).set('minute', 0).unix() * 1000
    }
}

const isPc = (!!window.navigator?.userAgent?.match(/compatible/i) || window.navigator?.userAgent?.match(/Windows/i)) || (!!window.navigator?.userAgent?.match(/Macintosh/i) || window.navigator?.userAgent?.match(/MacIntel/i))

const AudioContext = window.AudioContext || window.webkitAudioContext || undefined;
const Notification = window.Notification || window.webkitNotification || undefined;

function App() {
    const [config, setConfig] = useState({
        showDays: true,
    })

    const [endToday, setEndToday] = useState(endOfDay())
    const [today, setToday] = useState(endOfDay() / 1000 - dayjs().unix())

    const [endMonth, setEndMonth] = useState(endOfMonth())
    const [month, setMonth] = useState(endOfMonth() / 1000 - dayjs().unix())

    const [timePeriod, setTimePeriod] = useState(nextTimePeriod())

    const [api, contextHolder] = notification.useNotification();

    const notify = (title, option) => {
        api.info({
            message: title,
            description: option.body,
            placement: 'top',
            duration: dayjs().unix() - option.timestamp / 1000,
            maxCount: 1,
        })

        if (AudioContext) {
            axios.get("/notify.wav", {
                timeout: 1000,
                responseType: 'arraybuffer',
            }).then((response) => {
                const audioContext = new AudioContext();
                audioContext.decodeAudioData(response.data, function (buffer) {
                    const source = audioContext.createBufferSource();
                    source.buffer = buffer;
                    source.connect(audioContext.destination);
                    source.start();
                }, function (e) {
                    console.log('获取文件失败', e);
                }).then(r => {
                });
            })
        } else {
            console.error('系统不支持声音通知')
        }

        if (Notification) {
            Notification?.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    new Notification(title, option)
                } else {
                    console.error('用户已拒绝桌面通知权限')
                }
            })
        } else {
            console.error('系统不支持桌面通知')
        }
    }

    useEffect(() => {
        notify('考研倒计时', {
            body: '考研倒计时已启动',
            lang: 'zh-CN',
            timestamp: (dayjs().unix() + 5) * 1000,
        })
    }, [])

    return (
        <>
            {contextHolder}

            <Row
                align={'middle'}
                justify={'center'}
                style={{
                    minWidth: '80vw',
                    minHeight: '80vh',
                }}
            >
                <Tabs
                    defaultActiveKey="倒计时"
                    centered
                >
                    <Tabs.TabPane
                        tab={"倒计时"}
                        key={"倒计时"}
                    >
                        <Col>
                            <Row>
                                <ConfigProvider
                                    theme={{
                                        "components": {
                                            "Statistic": {
                                                "contentFontSize": 80,
                                                "titleFontSize": 30,
                                                "algorithm": true,
                                                "colorText": "rgb(243, 152, 148)"
                                            }
                                        }
                                    }}
                                >
                                    <Countdown
                                        title={(<>考研倒计时</>)}
                                        value={1703289599000}
                                        format="D 天 H 时 m 分 s 秒"
                                    />
                                </ConfigProvider>
                            </Row>
                            <Row
                                hidden={!config.showDays}
                                justify={'space-evenly'}
                            >
                                <ConfigProvider
                                    theme={{
                                        "components": {
                                            "Statistic": {
                                                "algorithm": true,
                                                "colorText": "#a67eb7"
                                            }
                                        }
                                    }}
                                >
                                    <Col
                                        hidden={!config.showDays}
                                    >
                                        <Row
                                            hidden={!config.showDays}
                                            justify={'center'}
                                        >
                                            <Countdown
                                                hidden={!config.showDays}
                                                title={'今日剩余'}
                                                value={endToday}
                                                format="H 时 m 分 s 秒"
                                                onChange={(value) => {
                                                    setToday(endOfDay() / 1000 - dayjs().unix())
                                                }}
                                                onFinish={() => {
                                                    setEndToday(endOfDay())
                                                    setToday(endOfDay() / 1000 - dayjs().unix())
                                                }}
                                            />
                                        </Row>
                                        <Row
                                            hidden={!config.showDays}
                                            justify={'center'}
                                        >
                                            <Progress
                                                hidden={!config.showDays}
                                                type={'circle'}
                                                percent={today / 86400 * 100}
                                                strokeWidth={10}
                                                format={(percent) => `${percent.toFixed(2)} %`}
                                                strokeColor={{
                                                    '0%': '#ffccc7',
                                                    '50%': '#ffe58f',
                                                    '100%': '#87d068',
                                                }}
                                            />
                                        </Row>
                                    </Col>

                                    <Col
                                        hidden={!config.showDays}
                                    >
                                        <Row
                                            hidden={!config.showDays}
                                            justify={'center'}
                                        >
                                            <Countdown
                                                hidden={!config.showDays}
                                                title={'本月剩余'}
                                                value={endMonth}
                                                format="D 天 H 时 m 分 s 秒"
                                                onChange={(value) => {
                                                    setMonth(endOfMonth() / 1000 - dayjs().unix())
                                                }}
                                                onFinish={() => {
                                                    setEndMonth(endOfMonth() * 1000)
                                                    setMonth(endOfMonth() / 1000 - dayjs().unix())
                                                }}
                                            />
                                        </Row>
                                        <Row
                                            hidden={!config.showDays}
                                            justify={'center'}
                                        >
                                            <Progress
                                                hidden={!config.showDays}
                                                type={'circle'}
                                                percent={month / (monthDays() * 86400) * 100}
                                                strokeWidth={10}
                                                format={(percent) => `${percent.toFixed(2)} %`}
                                                strokeColor={{
                                                    '0%': '#ffccc7',
                                                    '50%': '#ffe58f',
                                                    '100%': '#87d068',
                                                }}
                                            />
                                        </Row>
                                    </Col>

                                </ConfigProvider>
                            </Row>
                        </Col>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={"番茄时钟"}
                        key={"番茄时钟"}
                    >
                        <ConfigProvider
                            theme={{
                                "components": {
                                    "Statistic": {
                                        "contentFontSize": 80,
                                        "titleFontSize": 30,
                                        "algorithm": true,
                                        "colorText": "rgb(243, 152, 148)"
                                    }
                                }
                            }}
                        >
                            <Col>
                                <Row>
                                    <Countdown
                                        title={'当前 ' + timePeriod.state + ' 时段剩余'}
                                        value={timePeriod.end}
                                        format="m 分 s 秒"
                                        onChange={(value) => {
                                        }}
                                        onFinish={() => {
                                            const period = nextTimePeriod()
                                            console.log(period)
                                            setTimePeriod(period)


                                            notify(period.state + ' 时段已开始',
                                                {
                                                    body: '您已结束 ' + timePeriod.state + ' 时段，开始 ' + period.state + ' 时段，共计 ' + ((period.end / 1000 - dayjs().unix()) / 60 + 0.5).toFixed(0) + ' 分钟',
                                                    lang: 'zh-CN',
                                                    timestamp: (dayjs().unix() + 60) * 1000,
                                                })
                                        }}
                                    />
                                </Row>
                            </Col>
                        </ConfigProvider>
                    </Tabs.TabPane>
                </Tabs>
            </Row>

            <FloatButton.Group
                trigger="hover"
                icon={<SettingOutlined/>}
            >
                <FloatButton
                    tooltip={'显示/隐藏今日/本月倒计时'}
                    onClick={() => {
                        setConfig({
                            showDays: !config.showDays,
                        })
                    }}
                    icon={'📅'}
                />
            </FloatButton.Group>
        </>
    )
}

export default App;
